<template>
  <div class="my-container">
    <div class="fix-switch">
      <span :class="{'ft-nm':locale==='zh-tw'}" @click="switchLocale('zh-tw')">繁</span>
      /
      <span :class="{'ft-nm':locale==='zh-cn'}" @click="switchLocale('zh-cn')">简</span>
    </div>
    <div class="fix-logo">{{ $t('intro.logo') }}</div>
    <div class="my-wrapper">
      <div class="my-title" v-if="!is_loading">{{ $t('intro.title') }}</div>
      <div class="my-button" v-if="!is_loading" @click="click">{{ $t('intro.button') }}</div>
      <div class="my-title" v-if="is_loading">{{ $t('intro.label1') }}</div>
      <div class="my-title" v-if="is_loading">{{ $t('intro.label2') }}</div>
      <div id="progressBarContainer" v-if="is_loading">
        <div id="progressBar"></div>
      </div>
      <div class="my-tip" v-if="!is_loading">{{ $t('intro.note1') }}</div>
      <div class="my-tip" v-if="!is_loading">{{ $t('intro.note2') }}</div>
    </div>
    <div class="my-footer">
      <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备2024045068号-1</a>
    </div>
    <Login ref="login_model" @login-result="afterLogin"></Login>
  </div>
</template>

<script>
const Login = () => import("@/components/Login")

export default {
  name: "Intro",
  components: {
    Login
  },
  data() {
    return {
      is_loading: false,
      locale: 'zh-cn'
    }
  },
  mounted() {
    let locale = localStorage.getItem("locale")
    if (locale) {
      this.$i18n.locale = locale
      this.locale = locale
    }
  },
  methods: {
    switchLocale: function (lang) {
      this.locale = lang
      this.$i18n.locale = lang
      localStorage.setItem("locale", lang)
    },
    click: function () {
      let me = this
      const token = localStorage.getItem("token");
      if (!token) {
        me.$refs.login_model.v_login_form = true
      } else {
        this.axios.get("/settings/user").then((res1) => {
          localStorage.setItem('user_info', JSON.stringify(res1.data))
          me.showLoading()
        }).catch(() => {
          me.$refs.login_model.v_login_form = true
        })
      }
    },
    showLoading: function () {
      let me = this
      me.is_loading = true
      let progress = 0;
      let interval = setInterval(function () {
        progress += 0.333;
        if (progress > 100) progress = 100
        me.updateProgressBar(progress);
        if (progress >= 100) {
          clearInterval(interval);
          me.$router.push("/home/workspace")
        }
      }, 10);
    },
    updateProgressBar: function (progress) {
      let progressBar = document.getElementById('progressBar');
      progressBar.style.width = progress + '%';
    },
    afterLogin: function (isOk) {
      let me = this
      if (isOk === 'ok') {
        me.showLoading()
      }
    }
  }
}
</script>

<style scoped>
.my-container {
  width: 100%;
  height: 100%;
  background-color: #F7CBA8;
  background-image: url("../assets/backgroud.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.my-wrapper {
  margin-left: 50%;
}

.my-title {
  width: 4rem;
  font-size: 0.4rem;
}

.my-button {
  width: 1.2rem;
  height: 0.48rem;
  font-size: 0.24rem;
  line-height: 0.48rem;
  text-align: center;
  border: 2px solid #000000;
  border-radius: 0.24rem;
  background-color: #FFFBEA;
  cursor: pointer;
  margin-top: 0.2rem;
}

#progressBarContainer {
  margin-top: 0.2rem;
  width: 4rem;
  height: 0.24rem;
  border: 2px solid #000;
  background-color: transparent;
}

#progressBar {
  height: 100%;
  width: 0;
  background-image: repeating-linear-gradient(
      -45deg,
      #000,
      #000 10px,
      transparent 10px,
      transparent 20px
  );
}

/deep/ .login-modal .el-dialog .el-dialog__header {
  font-size: 18px;
}

.my-tip {
  font-size: 0.16rem;
  font-weight: lighter;
  position: relative;
  top: 1.5rem;
}

.fix-logo {
  position: fixed;
  top: 0.16rem;
  right: 0.8rem;
  color: rgb(255, 251, 235);
  font-size: 0.16rem;
  font-weight: lighter;
}

.fix-switch {
  position: fixed;
  top: 0.16rem;
  right: 0.16rem;
  color: rgb(255, 251, 235);
  font-size: 0.16rem;
  font-weight: lighter;
  cursor: pointer;
}

.ft-nm {
  font-weight: bold;
}

.my-footer{
  position: fixed;
  bottom: 0.2rem;
  right: 0.2rem;
  font-size: 0.12rem;
}
</style>
